import moment from 'moment';
import React from 'react';
import _ from 'lodash';

class TourModule {
    getTourTimes(tour, date) {
        const tourDate = this.getTourDate(tour, date, date);

        return tourDate.tourTimes;
    }

    getTourDatesAndTimesFromDate(tour, date) {
        const tourDates = this.getTourDatesFromDate(tour, date);
        const tourDatesAndTimes = tourDates.map(tourDate => {
            return tourDate.tourTimes.map(tourTime => ({tourDate, tourTime}));
        })
        return _.flatten(tourDatesAndTimes);
    }

    getTourTimesFromDate(tour, date) {
        const tourDates = this.getTourDatesFromDate(tour, date);
        const tourTimes = tourDates.map(tourDate => {
            return tourDate.tourTimes.filter(tourTime => {
/*
                tourTime.tourDate = tourDate;
*/
                return tourTime;
            });
        });
        return _.flatten(tourTimes);
    }

    getTourDatesFromDate(tour, fromDate) {
        return tour.tourDates.filter(tourDate => {
            if (tourDate.permanent) {
                return tourDate;
            }
            const toDate = tourDate.toDate || tourDate.fromDate;
            const dateInRange = moment(toDate).isSameOrAfter(fromDate);
            if (dateInRange) {
                return tourDate;
            }

            return false;
        });
    }

    getTourDate(tour, fromDate, toDate) {
        const tourDate = tour.tourDates.find(tourDate => {
            if (tourDate.permanent) {
                return tourDate;
            }
            const fromDateInRange = moment(tourDate.fromDate).isSameOrBefore(fromDate) && moment(tourDate.toDate).isSameOrAfter(fromDate);
            const toDateInRange = moment(tourDate.fromDate).isSameOrBefore(toDate) && moment(tourDate.toDate).isSameOrAfter(toDate);
            if (fromDateInRange || toDateInRange) {
                return tourDate;
            }

            return false;
        });

        if (!tourDate) {
            return {
                limit: 0,
                message: 'A megadott időpontra a túrafoglalás nem elérhető!',
            };
        }

        return tourDate;
    }

    getTour(tours, tourId) {
        return tours.find(tour => tour.id === tourId);
    }

    getDefaultTourProduct(tour) {
        return tour.products.find(product => product.default);
    }

    getTourAmountLimit(tourAmount, tourDate) {
        return Math.max(0, tourDate.limit - tourAmount);
    }

    hasTickets(tour) {
        return tour.products && tour.products.length > 0;
    }

    formatSummary(program) {
        if (!program) {
            return null;
        }

        let summary = null;

        let summaryItems = [];
        if (program.distance && program.distance !== '') {
            summaryItems.push(program.distance);
        }
        if (program.time && program.time !== '') {
            summaryItems.push(program.time);
        }
        if (program.difficulty && program.difficulty !== '') {
            summaryItems.push(program.difficulty);
        }

        if (summaryItems.length > 0) {
            summary = summaryItems.join(', ');
        }

        const tourDateInterval = tourModule.getTourDateInterval(program);
        if (tourDateInterval) {
            summary = `${summary ? `${summary} - ` : ''}${this.formatTourDateInterval(tourDateInterval)}`;
        }

        return summary;
    }

    tourGuideHasTours(tourGuide) {
        return tourGuide.tours && tourGuide.tours.length > 0;
    }

    tourHasTickets(tour) {
        return tour.hasTickets;
    }

    getTourLists(tours) {
        const tourListMap = {};

        tours.forEach(tour => {
            if (!tourListMap[tour.tourListId]) {
                tourListMap[tour.tourListId] = [];
            }
            tourListMap[tour.tourListId].push(tour);
        });

        const tourLists = Object.keys(tourListMap).map(key => {
            return tourListMap[key];
        }).sort((tourList1, tourList2) => {
            if (tourList1[0].tourList.index < tourList2[0].tourList.index) {
                return -1
            }
            if (tourList1[0].tourList.index > tourList2[0].tourList.index) {
                return 1
            }
            return 0;
        });

        return tourLists || [];
    }

    getTourDateInterval(tour) {
        if (!tour.tourDates ||
            tour.tourDates.length === 0 ||
            tour.tourDates.length > 1 ||
            !tour.tourDates[0].fromDate) {
            return null;
        }

        return {
            fromDate: moment(tour.tourDates[0].fromDate),
            toDate: tour.tourDates[0].toDate && moment(tour.tourDates[0].toDate)
        };
    }

    dateHasCalendarEvents(tours, date) {
        return tours.find(tour => tour.tourDates.find(tourDate => moment(tourDate.fromDate).isSame(moment(date.format('YYYY-MM-DD')))));
    }

    getToursForDate(tours, date) {
        return tours.filter(tour => tour.tourDates.find(tourDate => moment(tourDate.fromDate).isSame(moment(date.format('YYYY-MM-DD')))));
    }

    formatTourDateInterval(tourDateInterval) {
        const tourDateArray = [];
        const fromDate = moment(tourDateInterval.fromDate);
        const toDate = moment(tourDateInterval.toDate);

        if (!fromDate.isValid()) {
            return '';
        }

        tourDateArray.push(fromDate.format('YYYY. MM. DD.'));

        if (toDate.isValid() && !fromDate.isSame(toDate, 'day')) {
            tourDateArray.push(toDate.format('YYYY. MM. DD.'));
        }

        return tourDateArray.join(' - ');
    }

    formatTourDateIntervalAsDayOfWeek(tourDateInterval) {
        const tourDateArray = [];
        const fromDate = moment(tourDateInterval.fromDate);
        const toDate = moment(tourDateInterval.toDate);

        if (!fromDate.isValid()) {
            return '';
        }

        tourDateArray.push(fromDate.format('dddd'));

        if (toDate.isValid() && !fromDate.isSame(toDate, 'day')) {
            tourDateArray.push(toDate.format('dddd'));
        }

        return tourDateArray.join(' - ');
    }
}

export const tourModule = new TourModule();
